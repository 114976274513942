*::-webkit-scrollbar {
  width: 16px;
  background: rgb(230, 138, 0);
  border-radius: 30px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;
  background-color: rgb(3, 102, 25);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: rgba(0, 0, 0, 0.432);
}

button.MuiButton-variantSolid {
  background-color: rgb(0, 88, 0);
  color: white;
}

button.css-ucu6x8-JoyButton-root:hover {
  background-color: #f57c00;
}

button.MuiButton-variantSolid:hover,
button.MuiButton-variantOutlined:hover {
  background-color: #f57c00;
}

@keyframes levitation {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

.levitation {
  animation: levitation 2s ease-in-out infinite;
}